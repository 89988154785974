import React, { useContext, useRef, useMemo, useState, useEffect } from "react";

import { animate, motion } from "framer-motion";
import { CluesContext } from "../pages";

function replaceCharsWithSpaces(str, reveal1, reveal2, reveal3) {
  //console.log("str", str);
  const chars = str.split("");

  for (let i = 0; i < chars.length; i++) {
    if (i !== reveal1 && i !== reveal2 && i !== reveal3) {
      chars[i] = "_";
    }
  }
  return chars.join("");
}

const ClueBox = (props) => {
  const { clues, setFocusClueIndex, focusClueIndex, inputRef, checks } =
    useContext(CluesContext);
  //console.log("inputValues", inputValues);
  const cluesIndex = props.cluesIndex;
  const clue = clues[props.cluesIndex];
  //console.log("Create Cluebox for " + clue.keyword + " at " + cluesIndex);
  let input = clue.input;

  const ClueInput = (props) => {
    const fontSize = props.fontSize;
    //console.log("clueinput");
    //console.log("clue", clue);
    if (clue && clue.keyword) {
      //console.log("clue.keyword", clue.keyword);

      //Requirements
      //need a string representing the combination of revealed and guessed
      //
      const inputDiv2 = (
        <span
          key="inputDiv"
          className="text-6xl font-bold"
          style={{ fontSize: fontSize }}
        >
          {input
            .toUpperCase()
            .split("")
            .map((char, index) => (
              <motion.span
                className={
                  index === clue.nonRevealPositions[clue.cursorPosition]
                    ? "blinking"
                    : ""
                }
              >
                {char}
              </motion.span>
            ))}
        </span>
      );

      //        console.log("ClueInput html", input);
      return inputDiv2;
    } else return <></>;
  };
  useEffect(() => {}, [checks]);

  function StaticIncompleteKeyword() {
    return checks[clue.id]
      ? clue.keyword.toUpperCase()
      : replaceCharsWithSpaces(
          clue.keyword,
          clue.reveal1,
          clue.reveal2,
          clue.reveal3
        )
          .split("")
          .map((char, i) => (
            <span
              key={char + i}
              className="font-bold"
              style={{ padding: "0.05em" }}
            >
              {char}
            </span>
          ));
  }

  if (!clue || !clue.reveal2) return <div className="text-white">error</div>;
  else
    return (
      <div key={"main" + clue.id}>
        <motion.div id={clue.id} key={"motion" + clue.id}>
          <div
            className="mx-1 text-md flex flex-col text-center"
            key={"clue" + clue.id}
            style={{}}
          >
            <div
              className="my-1"
              style={{ fontSize: "1.8em" }}
              key={"font" + clue.id}
            >
              <div className="p-1 mb-2" style={{ fontSize: "0.9em" }}>
                {clue.pretext + " "} <ClueInput fontSize="1em"></ClueInput>
                {false && <StaticIncompleteKeyword></StaticIncompleteKeyword>}
                {" " + clue.posttext}
                {checks[clue.id] && (
                  <div>
                    {" "}
                    <a href={clue.url} className="text-blue-600">
                      [link]
                    </a>
                  </div>
                )}
              </div>
              {false && (
                <div
                  style={{ fontSize: "2.0em" }}
                  key={"keyword" + clue.id}
                  id={"keyword" + clue.id}
                >
                  {checks[clue.id] ? (
                    clue.keyword.toUpperCase()
                  ) : (
                    <ClueInput fontSize="1.2em"></ClueInput>
                  )}
                </div>
              )}
            </div>
          </div>

          {clue.urlToImage ? (
            <div className="w-full h-40 overflow-hidden">
              <img
                src={clue.urlToImage}
                alt="img"
                className="w-full object-cover"
                style={{ objectPosition: "center", objectFit: "cover" }}
              ></img>
            </div>
          ) : (
            <></>
          )}
        </motion.div>
      </div>
    );
};
export default ClueBox;
