const CrosswordComponent = ({ crossword }) => {
  const firstClue = crossword[0];
  const secondClue = crossword[1];
  const thirdClue = crossword[2];

  const letterMap = {};

  const firstRowCoordinates = () => {
    const string = firstClue.keyword;
    for (let i = 0; i < string.length; i++) {
      const letter = string[i];

      if (!letterMap[0]) letterMap[0] = {};

      letterMap[0][i] = letter;
    }
    console.table("firstRowCoordinates lettermap1", letterMap);
    secondCoordinates();
    thirdCoordinates();
    return letterMap;
  };

  const secondCoordinates = () => {
    const string = secondClue.keyword;
    for (let i = 0; i < string.length; i++) {
      const letter = string[i];
      console.log("letter", letter);

      //if the new row doesn't exist, create that new row
      if (!letterMap[i - firstClue.letterPosition])
        letterMap[i - firstClue.letterPosition] = {};

      letterMap[i - firstClue.letterPosition][firstClue.secondKeywordPosition] =
        letter;

      //if the cell to the left doesn't exist, create it
      if (
        !letterMap[i - firstClue.letterPosition][
          firstClue.secondKeywordPosition - 1
        ]
      )
        letterMap[i - firstClue.letterPosition][
          firstClue.secondKeywordPosition - 1
        ] = "";
    }
    console.table("secondCoordinates lettermap1", letterMap);
  };

  const thirdCoordinates = () => {
    const string = thirdClue.keyword;
    for (let i = 0; i < string.length; i++) {
      const letter = string[i];
      const row = secondClue.letterPosition - 1;
      const col =
        firstClue.letterPosition - secondClue.secondKeywordPosition + i;
      letterMap[row][col] = letter;

      Object.keys(letterMap).forEach((value, key) => {
        console.log(
          "thirdcoords key " + key + " has value " + value + " col " + col
        );
        console.log("letterMap[key]", letterMap[key]);
        if (!letterMap[key]) letterMap[key] = {};

        console.log("letterMap[key][col]", letterMap[key][col]);
        if (!letterMap[key][col]) {
          console.log("insert blank cell");
          letterMap[key][col] = "";
        }
        // console.log("success");
      });
    }
  };

  function createLetterMapTable(letterMap) {
    const sortedRows = Object.keys(letterMap).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );

    return (
      <div className="align-center text-center items-center">
        {sortedRows.map((i) => {
          const sortedCols = Object.keys(letterMap[i]).sort(
            (a, b) => parseInt(a) - parseInt(b)
          );
          //  console.log("sortedCols", sortedCols);
          return (
            <div className="flex flex-row" key={i}>
              {sortedCols.map((j) => (
                <div className="flex flex-col" key={`${i}-${j}`}>
                  <div
                    className="w-6 h-7 bg-white m-1 p-1 border-solid border-red-500 text-center font-bold items-center"
                    style={{
                      backgroundColor: letterMap[i][j]
                        ? "white"
                        : "transparent",
                    }}
                  >
                    {letterMap[i][j].toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      {createLetterMapTable(firstRowCoordinates())}
    </div>
  );
};
const domain = "https://newsword-get-news.alai22.workers.dev";
//const domain = "http://127.0.0.1:8787";
const url = domain + "/get-news";

async function getClues(setClues) {
  const res = await fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      console.log("got response from get-news", response);
      return response.json();
    })
    .then((data) => {
      console.log("json parsed", data);
      return data;
    })
    .catch((error) => {
      console.error("err", error);
    });

  var updatedRes = res.map(function (obj) {
    return {
      ...obj,
      input: replaceCharsWithSpaces(
        obj.keyword,
        obj.reveal1,
        obj.reveal2,
        obj.reveal3
      ),
      inputOriginal: replaceCharsWithSpaces(
        obj.keyword,
        obj.reveal1,
        obj.reveal2,
        obj.reveal3
      ),
      nonRevealPositions: findNonRevealPositions(
        obj.keyword,
        obj.reveal1,
        obj.reveal2,
        obj.reveal3
      ),
      cursorPosition: 0,
    };
  });

  setClues(updatedRes);
}

function findFirstNonReveal(reveal1, reveal2, reveal3) {
  var integer = 0;

  while (integer === reveal1 || integer === reveal2 || integer === reveal3) {
    integer++;
  }

  return integer;
}

function findNonRevealPositions(str, reveal1, reveal2, reveal3) {
  var positions = [];

  for (var i = 0; i < str.length; i++) {
    if (i !== reveal1 && i !== reveal2 && i !== reveal3) {
      positions.push(i);
    }
  }

  return positions;
}

function replaceCharsWithSpaces(str, reveal1, reveal2, reveal3) {
  //console.log("str", str);
  const chars = str.split("");

  for (let i = 0; i < chars.length; i++) {
    if (i !== reveal1 && i !== reveal2 && i !== reveal3) {
      chars[i] = "_";
    }
  }
  return chars.join("");
}

export { CrosswordComponent, getClues };
