import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useMemo,
} from "react";
import cookie from "js-cookie";
import useSound from "use-sound";
import sound from "../../src/assets/audio/soft-notice.mp3";
import { animate } from "framer-motion";
//import newswordLogo from "../../src/assets/newsword-inverted.webp";
import { CrosswordComponent, getClues } from "../functions/index_functions";
import ClueBox from "../components/ClueBox";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import axios from "axios";
export const CluesContext = createContext(null);
const Home = () => {
  const [clues, setClues] = useState();
  const [playActive] = useSound(sound, { volume: 0.22 });
  const [checks, setChecks] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);
  const countDiv = document.getElementById("count");
  const [clue, setClue] = useState();
  const [focusClueIndex, setFocusClueIndex] = useState(0);
  const [showKeyboard, setShowKeyboard] = useState(true);

  let userToken = cookie.get("newsword");
  if (!userToken) cookie.set("newsword", "blah2blah");
  //console.log("userToken", userToken);

  useEffect(() => {
    getClues(setClues);
  }, []);

  useEffect(() => {
    //    console.log("clues useEffect");
    if (clues) {
      //      console.log("clues useEffect has clues");
      if (focusClueIndex === 0 || focusClueIndex > 0) {
        //      console.log("clues useEffect has index");
        setClue(clues[focusClueIndex]);
      } else console.log("clues useEffect has no index", focusClueIndex);
    } else console.log("clues useEffect IF fails");
  }, [clues, focusClueIndex]);

  useEffect(() => {
    if (document.getElementsByClassName("react-simple-keyboard")[0]) {
      const keyboardBg = document.getElementsByClassName(
        "react-simple-keyboard"
      )[0];
      keyboardBg.style.backgroundColor = "white";
      console.log("keyboardBg", keyboardBg);
    }

    console.log("clue useEffect", clue);
    if (document.getElementsByClassName("hg-button")[0]) {
      const keyboardButtons = document.getElementsByClassName("hg-button");
      for (let i = 0; i < keyboardButtons.length; i++) {
        const button = keyboardButtons[i];
        //console.log("button", button);
        button.style.height = "55px";
        button.style.backgroundColor = "#d1d4db";
        //button.style.color = "";
        const spanElement = button.getElementsByTagName("span")[0];
        //console.log("spanElement", spanElement);
        //console.log("spanElement.textContent", spanElement.textContent);
        if (spanElement.textContent !== "CLR") {
          spanElement.style.fontWeight = "bold";
        } else spanElement.style.fontSize = "0.6em";
      }
    }
  }, [clue]);

  // if checks changes, animate
  useEffect(() => {
    if (clues && checks) {
      console.log("checks in useeffect", checks);

      let trueCount = 0;

      clues.forEach((clue) => {
        if (clue.complete) {
          trueCount++;
        }
      });

      if (trueCount === clues.length) {
        console.log("finished");
        setShowKeyboard(false);

        const gratsDiv = document.getElementById("grats");
        gratsDiv.style.display = "block";

        animate(gratsDiv, { scale: 3.6 }, { duration: 0.25 }).then(() => {
          animate(gratsDiv, { scale: 1 }, { duration: 0.25 });
        });
      }
      setCorrectCount(trueCount);
    }
  }, [clues, checks]);

  const animation = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  useEffect(() => {
    if (correctCount > 0) {
      playActive();
      if (countDiv) {
        animate(countDiv, { scale: 4.8 }, { duration: 0.1 }).then(() => {
          animate(countDiv, { scale: 1 }, { duration: 0.08 }).then(() => {
            console.log("go to next question");
          });
        });
      }
      setFocusClueIndex(focusClueIndex + 1);
    }
  }, [correctCount]);
  const inputRef = useRef(null);

  function NewsWordHeader(props) {
    return (
      <>
        {" "}
        <header className="py-3 header bg-black">
          <div className="flex justify-center items-center text-3xl font-bold text-white ">
            partial.news
          </div>
        </header>
      </>
    );
  }

  function ImageColumn(props) {
    const start = props.index === 0 ? 0 : 0;
    const end = props.index === 0 ? 3 : 8;
    return (
      <div className="flex flex-row h-8 w-12 mb-4" key="imageCol">
        {clues.slice(start, end).map((clue) => (
          <img
            alt="newsImg"
            className="aspect-auto object-cover object-top w-full h-full m-1"
            src={clue.urlToImage}
          ></img>
        ))}
      </div>
    );
  }

  console.log("clues", clues);

  useEffect(() => {
    console.log("useEffect triggered by focusClueIndex", focusClueIndex);
  }, [focusClueIndex]);
  const domain = "https://newsword-get-news.alai22.workers.dev";

  const checkInput = async (newInput) => {
    // Call the API with the latest input value {
    console.log("check answer api with guess", newInput);

    let inputValues = [];
    for (let clue of clues) {
      inputValues[clue.id] = clue.input;
    }
    try {
      const response = await axios.post(domain + "/check-answer", inputValues);
      const tempCheck = response.data[clue.id];
      console.log("response check", tempCheck);

      const tempChecks = response.data;
      console.log("tempChecks", tempChecks);

      if (tempChecks)
        if (tempChecks[clue.id]) {
          console.log("correct guess", newInput);
          let tempClues = [...clues];
          tempClues[focusClueIndex].complete = true;
          tempClues[focusClueIndex].input = "";
          clue.input = "";
          setClues(tempClues);
        } else {
          console.log("guess wrong");
        }
    } catch (error) {
      console.error(error);
    }
  };

  function processInput(button) {
    let input = clue.input ? clue.input : "";
    console.log("processInput button", button);
    switch (button) {
      case "⌫":
      case "Backspace":
        if (clue.cursorPosition > 0) {
          console.log("remove one character");
          clue.cursorPosition--;
          let chars = input.split("");
          chars[clue.nonRevealPositions[clue.cursorPosition]] = "_";
          input = chars.join("");
        }
        break;
      case "CLR": {
        clue.cursorPosition = 0;
        input = clue.inputOriginal;
        break;
      }
      default:
        if (
          clue.keyword.length > clue.nonRevealPositions[clue.cursorPosition]
        ) {
          console.log("clue.cursorPosition", clue.cursorPosition);
          console.log("character to replace", input[clue.cursorPosition]);
          let chars = input.split("");
          chars[clue.nonRevealPositions[clue.cursorPosition]] = button;
          input = chars.join("");
          clue.cursorPosition++;
        }
    }
    //console.log("input", input);
    let tempClues = [...clues];
    console.log("tempClues", tempClues);
    console.log("focusClueIndex", focusClueIndex);
    tempClues[focusClueIndex].input = input;
    setClues(tempClues);
    if (input.length === clue.keyword.length) checkInput(input);
  }
  const CustomKeyboard = useMemo(() => {
    console.log("render keyboard");

    const onKeyPress = (button) => {
      console.log("Button pressed", button);
      //console.log("onKeyPress clue", clue);

      processInput(button);
    };

    const onChange = (input) => {};
    if (showKeyboard)
      return (
        <Keyboard
          onChange={(input) => onChange(input)}
          onKeyPress={(input) => {
            onKeyPress(input);
          }}
          layout={{
            default: [
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "CLR z x c v b n m ⌫",
            ],
          }}
        />
      );
  }, [clue]);
  const documentRef = useRef(null);

  const KeyboardInputs = () => {
    useEffect(() => {
      const handleKeyDown = (event) => {
        // Access the pressed key
        const { key } = event;
        console.log("key pressed", key);

        // Perform actions based on the pressed key
        if (key === "Enter") {
          console.log("Enter key pressed");
          // Perform the desired action for the Enter key
        } else if (key === "Escape") {
          console.log("Escape key pressed");
          // Perform the desired action for the Escape key
        } else {
        }

        if (/^[a-zA-Z]$/.test(key) || key === "Backspace") {
          processInput(key);
          // Perform the desired action for the letter key or Backspace
        }
      };
      documentRef.current.focus();

      // Attach the event listener to the document object
      document.addEventListener("keydown", handleKeyDown);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return <div></div>;
  };
  return clues ? (
    <div
      key="app"
      ref={documentRef}
      className="App content-center flex justify-center flex-row"
    >
      <KeyboardInputs></KeyboardInputs>
      <div className="max-w-xl flex flex-col min-h-screen" key="body">
        <NewsWordHeader></NewsWordHeader>
        {false && <ImageColumn index={0}></ImageColumn>}
        {false && (
          <div className="grid grid-cols-2" key="prevnext">
            {focusClueIndex === 0 ? (
              <div key="noprev"></div>
            ) : (
              <button
                key="prev"
                className=""
                onClick={() => {
                  setFocusClueIndex(focusClueIndex - 1);
                }}
              >
                {"< Prev"}
              </button>
            )}

            {focusClueIndex === clues.length - 1 ? (
              <div key="nonext"></div>
            ) : (
              <button
                key="next"
                className=""
                onClick={() => {
                  setFocusClueIndex(focusClueIndex + 1);
                }}
              >
                {"Next >"}
              </button>
            )}
          </div>
        )}
        <div
          key="grats"
          style={{ display: "none" }}
          id="grats"
          className="font-bold italic text-4xl my-6"
        >
          Congratulations!
        </div>
        <div className="text-lg font-bold  text-white bg-gray-600 py-1">
          {correctCount} of {clues.length}
        </div>
        {
          <div className="flex flex-col" key="clues">
            <CluesContext.Provider
              value={{
                clues,
                setFocusClueIndex,
                focusClueIndex,
                inputRef,
                checks,
                setClues,
                setChecks,
              }}
            >
              <TestCarousel></TestCarousel>
            </CluesContext.Provider>
          </div>
        }
        {false && <ImageColumn index={1}></ImageColumn>}
        {false && <CrosswordComponent crossword={clues}></CrosswordComponent>}
        <div className="my-1"></div> {CustomKeyboard}
        {focusClueIndex > 0 && (
          <div className="my-3 mx-1 text-xs">
            <div>Last Headline</div>
            <a
              href={clues[focusClueIndex - 1].url}
              className="className text-blue-500 text-sm underline"
            >
              {clues[focusClueIndex - 1].pretext +
                " " +
                clues[focusClueIndex - 1].keyword +
                " " +
                clues[focusClueIndex - 1].posttext}
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );

  function Carousel() {
    return (
      <div key="carousel" style={{}}>
        {clues.map((clue, index) => (
          <div
            key={"clueContainer" + index}
            style={{ display: index === focusClueIndex ? "block" : "none" }}
          >
            <ClueBox
              key={"cluebox" + index}
              cluesIndex={index}
              slot={index}
            ></ClueBox>
          </div>
        ))}
      </div>
    );
  }

  function TestCarousel() {
    return <Carousel></Carousel>;
    return (
      <Carousel
        enableTouch={true}
        width="24em"
        showArrows={false}
        showThumbs={false}
      >
        <Carousel></Carousel>
      </Carousel>
    );
  }
};
export default Home;
